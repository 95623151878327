<template>
  <div class="ecommerce-application">
    <b-overlay rounded="sm" :show="show">
      <section class="invoice-add-wrapper">
        <b-form ref="formBudget">
          <input type="hidden" name="id" :value="budget_id">
          <input type="hidden" name="status" :value="budget.status">
          <!--TODO COMENTADO PARA LUEGO AÑADIR FIRMA CLIENTE-->
          <input type="hidden" name="consent_signature" :value="consent_signature">
          
          <b-row class="invoice-add">
            <b-col cols="12" xl="9" md="8">
              <fieldset>
                <b-card no-body class="invoice-preview-card">
                  <b-card-body class="invoice-padding form-item-section">
                    
                    <div ref="form" class="repeater-form">
                      <b-row v-for="item, index in budget.lines" :key="index" ref="row" class="pb-2">
                        <input type="hidden" :name="'lines[' + index + '][id]'" :value="item.id">
                        <b-col cols="12">
                          <div class="d-none d-lg-flex">
                            <b-row class="flex-grow-1 px-1">
                              <b-col cols="12" lg="2"> Producto </b-col>
                              <b-col cols="12" lg="3"> Descripción </b-col>
                              <b-col cols="12" lg="1"> Unidades</b-col>
                              <b-col cols="12" lg="2"> Precio </b-col>
                              <b-col cols="12" lg="2"> Descuento €</b-col>
                              <b-col cols="12" lg="2"><strong> Precio total</strong> </b-col>
                            </b-row>
                            <div class="form-item-action-col" />
                          </div>
                          <div class="d-flex border rounded">
                            <b-row class="flex-grow-1 p-2">
                              <b-col cols="12" lg="2">
                                <label class="d-inline d-lg-none">Producto</label>
                                <b-form-input maxlength="3000" required :name="'lines[' + index + '][concept]'"
                                  v-model="budget.lines[index].concept" class="mb-1 mb-sm-0" />
                              </b-col>
                              <b-col cols="12" lg="3">
                                <label class="d-inline d-lg-none">Descripción</label>
                                <b-form-textarea maxlength="3000" required :name="'lines[' + index + '][description]'"
                                  v-model="budget.lines[index].description" class="mb-1 mb-sm-0" />
                              </b-col>
                              <b-col cols="12" lg="1">
                                <label class="d-inline d-lg-none">Unidades</label>
                                <b-form-input :name="'lines[' + index + '][qty]'" type="number" required
                                  v-model="budget.lines[index].qty" class="mb-1 mb-sm-0" @change="recalc(index)" />
                              </b-col>
                              <b-col cols="12" lg="2">
                                <label class="d-inline d-lg-none">Precio</label>
                                <b-form-input :name="'lines[' + index + '][punit]'" type="number" class="mb-1 mb-sm-0"
                                  v-model="budget.lines[index].punit" @change="recalc(index)" />
                              </b-col>
                              <b-col cols="12" lg="2">
                                <label class="d-inline d-lg-none">Descuento €</label>
                                <b-form-input :name="'lines[' + index + '][pdiscount]'" type="number"
                                  class="mb-1 mb-sm-0" v-model="budget.lines[index].pdiscount"
                                  @change="recalc(index)" />
                              </b-col>
                              <b-col cols="12" lg="2">
                                <label class="d-inline d-lg-none">Precio total</label>
                                <input type="hidden" :name="'lines[' + index + '][ptotal]'"
                                  v-model="budget.lines[index].ptotal">
                                <p class="mb-1"> {{ checked ? budget.lines[index].ptotal : budget.lines[index].ptotal -
      budget.lines[index].pdiscount }} € </p>
                              </b-col>
                            </b-row>
                            <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                              <a href="#" class="mt-4" @click="removeItem(index)">
                              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ff2825" fill="none" stroke-linecap="round" stroke-linejoin="round" >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M4 7l16 0" />
                                <path d="M10 11l0 6" />
                                <path d="M14 11l0 6" />
                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                              </svg>
                            </a>
                                      <!-- <feather-icon size="16" icon="TrashIcon" class="cursor-pointer" 
                                v-if="budget.status == 0" /> -->
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                  <b-card-body class="invoice-padding pb-0">
                    <b-row>
                      <b-col cols="12" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                        <div class="invoice-total-wrapper">

                          <div class="invoice-total-item">
                            <p class="invoice-total-title">Base Imponible: </p>
                            <p class="invoice-total-amount pl-1"> {{ budget.subtotal }}€</p>
                            <input type="hidden" name="subtotal" v-model="budget.subtotal">
                          </div>
                          <div class="invoice-total-item">
                            <p class="invoice-total-title"> IVA (21%): </p>
                            <p class="invoice-total-amount"> {{ budget.iva }}€ </p>
                            <input type="hidden" name="iva" v-model="budget.iva">
                          </div>
                          <hr class="my-50">
                          <div class="invoice-total-item">
                            <p class="invoice-total-title"> Total: </p>
                            <p class="invoice-total-amount"> {{ budget.total }}€ </p>
                            <input type="hidden" name="total" v-model="budget.total">
                          </div>
                        </div>
                      </b-col>
                      <label for="product" class="font-weight-bold">Añadir producto</label>
                    <multiselect class="mb-4" v-model="selected" :options="options" :option-height="50" label='sku' :internal-search="false" @search-change="searchProduct" track-by='id' selectLabel='' @input="addProduct()" deselectLabel='' placeholder="Seleccione un producto">
                    <template slot="singleLabel" slot-scope="props">
                      <span class="option_desc"><span class="option_title">{{ props.option.sku }} -
                          {{ props.option.product.name }} - {{ props.option.text }}</span></span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <img v-if="props.option.picture !== null" class="option_img"                      
                        :src="$http.defaults.baseURL+'storage/app/private/'+props.option.picture" alt="image product">
                      <img v-else class="option_img" :src="$http.defaults.baseURL+'/storage/app/private/default_product.png'" alt="No_image">
                      <div class="option_desc">
                        <span class="option_title">{{ props.option.sku }} - {{ props.option.product.name }}</span>
                        <span class="option_small">{{ props.option.text }}</span>
                      </div>
                    </template>
                    </multiselect>
                      <a type="button" class="a-button mb-2 ml-2" @click="addLine"> Añadir línea + </a>
                    </b-row>
                  </b-card-body>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">

                  <!-- Note -->
                  <b-card-body class="invoice-padding pt-0">
                    <span class="font-weight-bold">Comentarios: </span>
                    <b-form-textarea maxlength="255" name="internal_note" v-model="budget.internal_note" />
                  </b-card-body>

                  <b-card>
                    <h5>Cobros</h5> 
                    <p class="invoice-total-amount">Importe pendiente: {{this.budget_amount_pending}}€</p>                 
                        <table class="table table-responsive">
                          <thead>
                              <tr>
                                  <th>Fecha</th>
                                  <th>Importe</th>
                                  <th>Descripción</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr v-for="item in receipts">
                                  <td>{{ formatDate(item.date) }}</td>
                                  <td>{{ item.amount }}</td>
                                  <td>{{ item.description }}</td>                               
                              </tr>                    
                          </tbody>
                      </table>
                    <b-button class="mt-1" :to="'/create-charge/' + budget.id" variant="success">Añadir cobro</b-button>
  
                  </b-card>
                </b-card>
              </fieldset>
            </b-col>
            <!-- Right Col: Card -->
            <b-col cols="12" md="4" xl="3" class="invoice-actions">
              <b-card class="client-card">
                <span class="font-weight-bold"><strong>{{ budget.doc_num }} <span pill :class="status[budget.status] ? 'bg-'+status[budget.status].class+' text-white radius' : ''">{{status[budget.status] ? status[budget.status].text : ''}}</span></strong>
                  <!-- <b-badge pill :variant="status[budget.status].class" class="ml-3" v-if="!show">{{status[budget.status].text}}</b-badge> -->
                </span>
                <hr>
                <div v-if="budget.client">
                  <h5 class="mb-25"><strong>Datos Cliente: </strong>
                    <!-- <b-button variant="success" class="ml-1 btn-sm" @click="editClient(budget.client.id)">Editar</b-button> -->
                  </h5>
                  <span>{{ budget.client.name }}</span>
                  <p>NIF/CIF: {{ budget.client.nif }}</p>
                  <p>Tel: {{ budget.client.phone1+ (budget.client.phone2 ? ' - '+budget.client.phone2 : '') }}</p>
                  <p>Email: {{ budget.client.email }}</p>
                  <hr>
                  <h5> <strong>Dirección Actuación</strong></h5>
                  <p>{{ budget.client.address }}</p>
                  <p>{{ budget.client.cp + ' ' + budget.client.location }}</p>
                </div>
                <hr>
                <div >
                  <h5 class="mb-25" v-if="budget.status == 5">Descargar Factura: <strong><a class="text-primary" @click="printInvoice()">{{budget.invoice.doc_num}}</a> </strong>
                    <!-- <b-button variant="success" class="ml-1 btn-sm" @click="editClient(budget.client.id)">Editar</b-button> -->
                  </h5>
                  <h5>
                    No facturado
                  </h5>      
                  
                </div>
                <hr>

                <!-- <b-button variant="success" block :disabled="budget.status != 0">Guardar Cambios</b-button> -->
                <b-button variant="success" block @click="updateBudget">Guardar Cambios</b-button>
                <b-button variant="info" :href="'/edit-task/' + budget.task_id" block>Volver a la tarea</b-button>

                <b-button variant="primary" block v-if="budget.status !== 4" v-b-modal.modal-signature>Firmar
                  presupuesto</b-button>

                <b-button variant="warning" block @click="print()">Descargar</b-button>
                <!-- <b-button variant="primary" block class="mt-1">
                  <span>Enviar Email</span> 
                  <span>Volver a enviar email</span>
                </b-button> -->
                <b-button variant="danger" block @click="sendEmail()">
                  <span v-if="this.budget.emailed != 1">Enviar Email</span>
                  <span v-else>Volver a enviar email</span>
                </b-button>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </section>

      <b-modal id="edit-client-modal" title="Editar datos cliente">
        <label class="mt-1">Nombre Fiscal</label><b-form-input type="text" name="name"
          v-model="client.name"></b-form-input>
        <label class="mt-1">NIF</label><b-form-input type="text" v-model="client.nif"></b-form-input>
        <label class="mt-1">Persona contacto</label><b-form-input type="text" v-model="client.contact"></b-form-input>
        <label class="mt-1">Teléfono contacto</label><b-form-input type="text"
          v-model="client.phone_aux"></b-form-input>
        <label class="mt-1">Teléfono</label><b-form-input type="text" v-model="client.phone"></b-form-input>
        <label class="mt-1">Email</label><b-form-input type="text" v-model="client.email"></b-form-input>
        <label class="mt-1">Dirección</label><b-form-input type="text" v-model="client.address1"></b-form-input>
        <label class="mt-1">Piso</label><b-form-input type="text" v-model="client.address2"></b-form-input>
        <label class="mt-1">Población</label>
        <!-- <v-select
          v-model="optionsLocationSelected"
          @search="onSearchLocation"
          :options="optionsLocation"
          label="name"
         />         -->
        <!-- <label class="mt-1">Población</label><b-form-input type="text" v-model="client.cp_municipality_id"></b-form-input> -->
        <label class="mt-1">Código postal</label><b-form-input type="text" v-model="client.cp"></b-form-input>
        <template #modal-footer="{ cancel }">
          <b-row class="w-100">
            <b-col md="4">
              <button class="btn btn-success btn-sm btn-block mb-1" @click="saveClient">Aceptar</button>
            </b-col>
            <b-col md="4">
              <button class="btn btn-secondary btn-sm btn-block" @click="cancel()">Cancelar</button>
            </b-col>
          </b-row>
        </template>
      </b-modal>
      <b-modal id="modal-signature" title="Firmar parte de trabajo">
        <b-card>
          <b-card-body>
            <VueSignature id="signature" width="100%" height="300px" ref="signaturePad" :options="optionsSignature"
              :scaleToDevicePixelRatio=false />
          </b-card-body>
        </b-card>
        <template #modal-footer="{ cancel }">
          <b-row class="w-100">
            <b-col md="4">
              <button class="btn btn-success btn-sm btn-block mb-1" @click="save_signature">Aceptar</button>
            </b-col>
            <b-col md="4">
              <!-- <button class="btn btn-danger btn-sm btn-block mb-1" @click="undo_signature">Borrar firma</button> -->
            </b-col>
            <b-col md="4">
              <button class="btn btn-secondary btn-sm btn-block" @click="cancel()">Cancelar</button>
            </b-col>
          </b-row>
        </template>
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
import VueSignature from "vue-signature-pad";
import vSelect from 'vue-select'
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import { VBModal,BRow, BCol, BCard, BBadge, BOverlay, BCardBody, BLink, BListGroup, BListGroupItem, BButton, BFormInput, BInputGroup, BForm, BFormTextarea, BFormFile, BFormCheckbox} from "bootstrap-vue";
export default {
  components: {vSelect,VBModal, Multiselect, VueSignature, BRow, BCol, BCard, BBadge, BCardBody, BLink, BButton, BListGroup, BListGroupItem, BOverlay, BFormInput, BInputGroup, BForm, BFormTextarea, BFormFile,BFormCheckbox},
  data() {
    return {  
      checked: false,
      consent_signature : null,    
      show: true,
      budget_id : this.$route.params.budget,
      client : {name : '', address1 : '', location : '', cp : '', phone: '', index : -1 },
      options: [],
      receipts: null,
      budget_amount_pending: 0,
      selected: '',
      budget : { lines : {}},
      status: [
        // { id:0 ,text: '', class: ''},
        { id: 0 ,text: '', class: ''},
        { id: 1 ,text: 'emitido', class: 'secondary'},
        { id: 2 ,text: '', class: ''},
        { id: 3, text: 'rechazado', class: 'danger'},
        { id: 4, text: 'aceptado', class: 'success'},
        { id: 5,text: 'facturado', class: 'warning'},
      ],
      optionsSignature: {
        penColor: "#333",
        onBegin: () => {this.$refs.signaturePad.resizeCanvas()}
      },
    };
  },
  created() {    

    this.$http.get("/api/getBudget?id=" + this.budget_id).then((response) => {
      this.budget = response.data;
      this.show = false
      // this.checked = this.budget.with_discount;
      this.prof = this.budget.client.fare_type;      
    })

    // recuperar productos
    this.$http.get("/api/getProducts").then((response) => {
      this.options = response.data;
      this.show = false
    })

    
    this.$http.get("/api/getAmountPending?id=" + this.budget_id).then((response) => {    
        this.budget_amount_pending = response.data
      });

    this.$http.get("/api/getReceipts?id=" + this.budget_id).then((response) => {    
        this.receipts = response.data
      });
    
  },
  methods: {   
    formatDate(dateString) {
      // Verifica que la cadena de fecha no esté vacía
      if (!dateString) return '';
      // Formatea la fecha usando Moment.js (por ejemplo, 'YYYY-MM-DD')
      return moment(dateString).format('DD-MM-YYYY');
    },
    searchProduct: function (query) {
      this.options = this.options.filter(e => (
        e.sku !== null && e.sku.toLowerCase().includes(query.toLowerCase()))
        || e.text.toLowerCase().includes(query.toLowerCase())
        || e.product.name.toLowerCase().includes(query.toLowerCase())
      );
    },
    // *************** Validation
    validate () {
      for (let i = 0; i < this.budget.lines.length; i++) {
        if(!this.budget.lines[i].concept || this.budget.lines[i].punit === ""|| this.budget.lines[i].qty === ""){
          return false
        } 
      }
      return true;
    },
    // *************** Calc functions
    format_import(value) {
      return (Math.round((value + Number.EPSILON) * 100) /100).toFixed(2);
    },
    recalc(index){
      let sum = 0;
      let ptotal = 0; let iva = 0 ; let total = 0; let discount = 0;
      
      if(index >= 0) {
        ptotal =  (this.budget.lines[index].punit * this.budget.lines[index].qty) - (((this.budget.lines[index].punit * this.budget.lines[index].qty) * this.budget.lines[index].pdiscount) / 100);
        this.budget.lines[index].ptotal = this.format_import(ptotal)
        this.$forceUpdate();
        for (let i = 0; i < this.budget.lines.length; i++) {
          sum += parseFloat(this.budget.lines[i].ptotal);
          discount += ((this.budget.lines[i].punit * this.budget.lines[i].qty) * (this.budget.lines[i].pdiscount) / 100)
        }
      }else{
        for (let i = 0; i < this.budget.lines.length; i++) {
          ptotal = 0
          ptotal =  (this.budget.lines[i].punit * this.budget.lines[i].qty) - (((this.budget.lines[i].punit * this.budget.lines[i].qty) * this.budget.lines[i].pdiscount) / 100);
          this.budget.lines[i].ptotal = this.format_import(ptotal)          
          sum += parseFloat(this.budget.lines[i].ptotal);
          discount += ((this.budget.lines[i].punit * this.budget.lines[i].qty) * (this.budget.lines[i].pdiscount) / 100)
        }
      }
      this.budget.subtotal = this.format_import(sum);
      iva = sum * 0.21;
      this.budget.iva = this.format_import(iva);
      total =  sum + iva;
      this.budget.discount = this.format_import(discount);
      this.budget.total = this.format_import(total);

    },

    addProduct() {
      let pvp = 0;
     
      if(this.prof != "0"){
        if(this.selected.pvp_prof > 0 && this.selected.pvp_prof !== null ){
          pvp = this.selected.pvp_prof;
        }else if (this.selected.pvp_prof_percent > 0 && this.selected.pvp_prof_percent !== null ) {
          pvp = (Math.round(((this.selected.pvp * (this.selected.pvp_prof_percent / 100)) + Number.EPSILON) * 100) /100)
        }else {
          pvp = this.selected.pvp
        }
      }else{
        pvp = this.selected.pvp
      }
      this.budget.lines.push({
        sku : this.selected.sku,
        variant_id: this.selected.id,
        concept : this.selected.product.name,  
        description: this.selected.text, 
        qty : 1, 
        punit : pvp,
        discount : 0.00, pdiscount : 0.00, c_unit : this.selected.cost
      });
      this.selected = '';
      this.recalc();
    },
    
    updateTotals(){
      if(this.checked == 0) {
        for (let i = 0; i < this.budget.lines.length; i++) {
          this.budget.lines[i].pdiscount = 0.00;
        }
        this.recalc(-1);
      }
    },
    updateBudget : async function (e) {
      if(!this.validate()){
        this.$bvToast.toast('ERROR : No pueden existir linias sin concepto, unidades o precio', { variant: 'danger',noCloseButton : true });
        return
      }
      this.show = true;
      var formData = new FormData(this.$refs.formBudget);
      
      await this.$http({
        method: "post",
        url: "/api/updateBudget",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } }
      }).then(response => { 
        this.show = false;
        this.budget = response.data;
        this.$bvToast.toast('Cambios guardados con exito', { variant: 'success',noCloseButton : true });
      }).catch(response => { 
          window.console.log(response);
          this.show = false;
          this.$bvToast.toast('Error al guardar los datos', { variant: 'danger',noCloseButton : false });
      });
      
    },
    
    // *************** CRUD LINES
    removeItem(index) {
      this.$bvModal.msgBoxConfirm('¿Desea borrar esta linia?', {size: 'sm',buttonSize: 'sm',okVariant: 'danger',okTitle: 'SI',cancelTitle: 'NO',hideHeaderClose: false,centered: true})
      .then(value => {
        if(value) {
          if (this.budget.lines[index].id) {
            this.show = true;
            this.$http.post("/api/removeLineReport",{id : this.budget.lines[index].id }).then((response) => {
              if (response.data) {
                this.show = false;
                this.$bvToast.toast('Linia eliminada con exito', { variant: 'success',noCloseButton : true });
                this.budget.lines.splice(index, 1)
                //this.recalc(index);
                this.recalc(-1);
              
              }else {
                alert('Los datos no han podido ser almacenados')
              }
           });                       
          }else {
            this.budget.lines.splice(index, 1)
          }
        }

      })
    },
    addLine() {
      this.budget.lines.push({concept : "",  qty : 1.00, punit : 0.00, pdiscount : 0.00, ptotal: 0.00 })
    },
    
    // ********************** firma 
    async save_signature() {
      await this.updateBudget();
      let that = this;
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        return alert('La firma es necesaria.');
      }
      let imagen = data.replace(/^data:image\/(png|jpg);base64,/, "");
      this.consent_signature = imagen;      
      this.$bvModal.hide('modal-signature')
        
      setTimeout(function() {
        that.updateBudget();
      }, 100);       
      // guardar firma y budget con status actualizado en Backend
    },
    undo_signature() {
      this.$refs.signaturePad.undoSignature();
    },    
    //CLIENTE
    editClient(index) {  
      this.client = this.budget.client;  
      this.$bvModal.show('edit-client-modal')
    },
    // NO UTILIZADO DUPLICADO ¿?
    // validate () {
    //   for (let i = 0; i < this.client.length; i++) {
    //     if(!this.client[i] == ""){
    //       return false
    //     } 
    //   }
    //   return true;
    // },
  
    // ******************************** Print & SEND
    print() {
      this.$http({
        url: "/api/printBudget/" + this.budget_id,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        if(response.status == 200){
          var fileURL = window.URL.createObjectURL(new Blob([response.data], {type:'application/pdf'}));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'presupuesto.pdf');
          document.body.appendChild(fileLink);
          fileLink.click();
        }
      });
    },
    sendEmail() {
      this.show = true;
      this.$bvModal.msgBoxConfirm('¿Desea enviar correo al cliente?', { size: 'sm', buttonSize: 'sm', okVariant: 'danger', okTitle: 'SI', cancelTitle: 'NO', hideHeaderClose: false, centered: true })
        .then(value => {
          if (value) {
            if (this.budget.client.email) {
              this.$http.get("/api/sendReportMail/" + this.budget_id, { timeout: 25000 }).then((response) => {
                this.budget.emailed = 1;
                this.show = false;
                this.$bvToast.toast('Presupuesto enviado con éxito,', { variant: 'success', noCloseButton: true });
              }).catch((e) => {
                this.show = false;
                this.$bvToast.toast('Error al enviar presupuesto, asegurate que el cliente tiene email introducido', { variant: 'danger', noCloseButton: false });
              });
            } else {
              this.$bvToast.toast('El cliente no tiene email introducido, añade uno para poder realizar el envío', { variant: 'success', noCloseButton: true });
            }
          }
        });
      },
   },
   
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" >
@import "~@core/scss/base/pages/app-invoice.scss";
@import '@core/scss/vue/libs/vue-select.scss';
.client-card p {
  margin-bottom: 0.25rem;
  font-size: 0.9rem;
}

.a-button {
  background-color: #ae2222;
  padding: 10px;
  color: white !important;
  border-radius: 4px;
}

.multiselect,
.multiselect__input,
.multiselect__tags,
.multiselect__single {
  font-size: 14px !important;
}

.multiselect {
  margin-bottom: 10px;
}

.multiselect__tags {
  border-color: #e4eaec;
}

.multiselect__option {
  padding: 6px;
  min-height: 20px;
}
.option_desc,
.option_img {
  display: inline-block;
  vertical-align: middle;
}

.option_img {
  max-height: 50px;
  margin-right: 10px;
  width: 50px;
}

.option_desc {
  padding: 10px;
}

.option_title {
  font-size: 12px;
  font-weight: bold;
}

.option_small {
  margin-top: 10px;
  display: block;
}
.remove-item{
  margin-top: 10%;
}
.radius{
  border-radius: 20px;
  padding: 4px 6px;
}
</style>